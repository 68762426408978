// @flow strict
import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Sidebar from '../components/Sidebar';
import Feed from '../components/Feed';
import Page from '../components/Page';
import { useSiteMetadata } from '../hooks';
import type { AllMarkdownRemark } from '../types';

type Props = {
  data: AllMarkdownRemark,
};

const ProjectTemplate = ({ data }: Props) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();

  const { edges } = data.allMarkdownRemark;

  return (
    <Layout title={siteTitle} description={siteSubtitle}>
      <Sidebar isIndex />
      <Page>
        <Feed edges={edges}/>
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query ProjectTemplate {
    allMarkdownRemark(
        filter: { frontmatter: { template: { eq: "project" }, draft: { ne: true } } },
        sort: { order: DESC, fields: [frontmatter___date] }
      ){
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            template
            date
            description
            socialImage
            paperLink
            codeLink
          }
        }
      }
    }
  }
`;

export default ProjectTemplate;
